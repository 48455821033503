<template>
  <el-container class="elcontainer">
    <!-- 头部 -->
    <el-header height="60px" class="header">
      <div class="header_left">金豆央厨扫码溯源系统V1.0</div>
      <img @click="off" style="width:30px;height:25px;" src="../assets/img/caidan.png" alt="">
      <div class="header_right">
        <div>
          <span class="span1">{{real_name}}</span>
          <img class="user_img" src="../assets/img/admin.png" alt="">
          <span class="span2">账号: {{username}}</span>
          <el-button size="small" class="Logout" type="info" plain @click="Logout">注销</el-button>
        </div>
      </div>
    </el-header>
    <el-container class="elcontainerbox">
      <!-- 左侧 -->
      <el-aside :width="isCollapse ? '70px' : '230px'">
        
        <el-menu
          :collapse-transition="false"
          :collapse="isCollapse"
          :default-active="activePath"
          background-color="#323543"
          text-color="#fff"
          active-text-color="#1CA6C8"
          unique-opened
          router
          :class=" isCollapse ? 'el_menu_vertical1' : 'el_menu_vertical'"
        >
          <!-- <el-menu-item index="/welcome" @click="saveNavState('/welcom')">
            <i class="el-icon-s-home"></i>
            <span>首页</span>
          </el-menu-item> -->
          <el-submenu :index="i + ''" v-for="(it,i) in menuList" :key="i">
            <template slot="title">
               <i :class="icons[i]"></i>
              <span>{{it.title}}</span>
            </template>
            <el-menu-item :index="item.url" @click="saveNavState(item.url)" v-for="(item,e) in it.childrens" :key="e">
              <template slot="title">
                <span style="margin-left:10px;">{{item.title}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
           <!-- <el-submenu index="0">
            <template slot="title">
               <i class="el-icon-user-solid"></i>
              <span>常规配置</span>
            </template>
            <el-menu-item index="/personal" @click="saveNavState('/personal')">
              <template slot="title">
                <span style="margin-left:10px;">个人信息</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/system" @click="saveNavState('/system')">
              <template slot="title">
                <span style="margin-left:10px;">系统配置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/imgs" @click="saveNavState('/imgs')">
              <template slot="title">
                <span style="margin-left:10px;">现场图片</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="1">
            <template slot="title">
               <i class="el-icon-user-solid"></i>
              <span>账号管理</span>
            </template>
            <el-menu-item index="/personal" @click="saveNavState('/personal')">
              <template slot="title">
                <span style="margin-left:10px;">个人信息</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/all" @click="saveNavState('/all')">
              <template slot="title">
                <span style="margin-left:10px;">用户列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
               <i class="el-icon-info"></i>
              <span>企业信息</span>
            </template>
            <el-menu-item index="/introduction" @click="saveNavState('/introduction')">
              <template slot="title">
                <span style="margin-left:10px;">企业介绍</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/connect" @click="saveNavState('/connect')">
              <template slot="title">
                <span style="margin-left:10px;">联系我们</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
               <i class="el-icon-s-goods"></i>
              <span>产品管理</span>
            </template>
            <el-menu-item index="/product" @click="saveNavState('/product')">
              <template slot="title">
                <span style="margin-left:10px;">产品列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
               <i class="el-icon-s-help"></i>
              <span>码包管理</span>
            </template>
            <el-menu-item index="/codeList" @click="saveNavState('/codeList')">
              <template slot="title">
                <span style="margin-left:10px;">码包列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          
          <el-submenu index="7">
            <template slot="title">
               <i class="el-icon-s-data"></i>
              <span>扫码数据</span>
            </template>
            <el-menu-item index="/scanCode" @click="saveNavState('/scanCode')">
              <template slot="title">
                <span style="margin-left:10px;">扫码列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">
               <i class="el-icon-s-opportunity"></i>
              <span>反馈管理</span>
            </template>
            <el-menu-item index="/opinion" @click="saveNavState('/opinion')">
              <template slot="title">
                <span style="margin-left:10px;">反馈列表</span>
              </template>
            </el-menu-item>
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <!-- 右侧 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '',
      menuList: [],

      // 用户信息
      username: '',
      real_name: '',
      icons: [
        'el-icon-s-tools',
        'el-icon-s-custom',
        'el-icon-s-shop',
        'el-icon-s-claim',
        'el-icon-s-promotion'

      ]
      
      
    }
  },
  created() {
    this.activePath = sessionStorage.getItem('jd_activePath')
    this.info()
    this.ruleinfo()
  },
  methods: {
     //  获取用户信息
    async info() {
      const { data: res } = await this.$http.get('api/user/info')
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.username= res.data.username
      this.real_name= res.data.real_name
    },
    // 获取权限菜单
    async ruleinfo() {
      const { data: res } = await this.$http.get('api/ruleinfo')
      if (res.code != 200) return this.$message.error(res.msg)
      this.menuList = res.rule
    },
    // 注销
    Logout() {
      window.localStorage.removeItem('jd_token')
      window.sessionStorage.removeItem('jd_activePath')
      this.$router.push('/login')
    },

    // 切换
    off() {
      this.isCollapse = !this.isCollapse
    },
    
    // 保存链接的激活状态
    saveNavState(activePath) {
      console.log(11)
      window.sessionStorage.setItem('jd_activePath', activePath)
      this.activePath = activePath
    },
    
  }
}
</script>
<style lang="less" scoped>

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: #323543;
  color: #fff;
  .header_left {
    width: 330px;
    height: 100%;
    font-size: 22px;
    text-align: center;
    line-height: 60px;
    letter-spacing:2px;
  }
  .header_right {
    flex: auto;
    height: 60px;
    // border-bottom: 1px solid #dfe3e8;
    // box-shadow: 0 0 10px #dfe3e8;
    line-height: 60px;
    div {
      float: right;
      display: flex;
      align-items: center;
    }
    .span1 {
      font-size: 16px;
    }
    .user_img {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 23px;
      margin-left: 20px;
    }
    .span2 {
      font-size: 16px;
      margin: 0 30px 0 6px;
    }
    .Logout {
      margin-right: 30px;
    }
  }
}
.elcontainer {
  height: 100vh;
}
.elcontainerbox {
  height: calc(100% - 60px);
}
aside,
menu,
main {
  height: 100%;
}
aside {
  background-color: #323543;
  width: 230px;
  overflow: hidden;
}

main {
  background-color: #edf2f8;
  padding: 10px!important;
}

.el_menu_vertical {
  width: 230px;
  overflow: hidden;
}
.el_menu_vertical1 {
  width: 70px;
  overflow: hidden;
}

.el-menu-item.is-active {
  color: #fff!important;
  background-color: rgb(28, 166, 200)!important;
}
</style>


